<template>
  <v-app>
    <v-dialog v-model="dialog" max-width="750">
      <v-card>
        <v-toolbar dark>
          <v-card-title>
            {{`${edit?"Update":"Add"} transport allocation`}}
            <hr>
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="cancelForm">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col md="12">
              <v-chip class="ma-2"  close @click:close="removeTransportAllocation(index)" color="primary"
                      v-if="userDetail"
                      label>
                {{ userDetail.display_text }}
              </v-chip>
              <v-autocomplete @change="changeUser" dense outlined :search-input.sync="userSearch" v-model="userDetail" :filter="() => true" chips
                              :items="users" clearable item-text="display_text" return-object >
                <template v-slot:no-data>
                  <div class="p-3 font-size-sm">Enter the student name or email </div>
                </template>
                <template v-slot:label>
                  Select student
                </template>
              </v-autocomplete>
              <span class="text-danger" v-if="$v.transport_allocation.user_id.$error">This information is required</span>
            </v-col>
            <v-col md="6">
              <v-select @change="changeStop" v-model="transport_allocation.route_id" dense outlined label="Route" :items="routes" item-text="title" item-value="id"></v-select>
              <span class="text-danger" v-if="$v.transport_allocation.route_id.$error">This information is required</span>
            </v-col>
            <v-col md="6">
              <v-select v-model="transport_allocation.stop_id" dense outlined label="Stop"  item-text="title" item-value="id" :items="stops"></v-select>
              <span class="text-danger" v-if="$v.transport_allocation.stop_id.$error">This information is required</span>
            </v-col>
            <v-col md="6">
              <v-select @change="changeDiscountType" dense outlined v-model="transport_allocation.discount_type" label="Discount type" :items="[{text:'Fixed',value:'fixed'},{text:'Percentage',value:'percentage'}]" item-value="value" item-text="text">

              </v-select>
            </v-col>
            <v-col md="6">
              <v-text-field dense outlined v-model="transport_allocation.discount" :label="transport_allocation.discount_type=='percentage' ? 'Discount  (%)': 'Discount'"></v-text-field>
              <span class="text-danger" v-if="$v.transport_allocation.discount.$error">This information is required</span>
            </v-col>
            <v-col md="6">
              <v-text-field dense outlined v-model="transport_allocation.fee" label="Fee"></v-text-field>
              <span class="text-danger" v-if="$v.transport_allocation.fee.$error">This information is required</span>
            </v-col>
            <v-col md="6">
              <v-text-field dense outlined v-model="transport_allocation.fee_frequency" label="Fee frequency"></v-text-field>
              <span class="text-danger" v-if="$v.transport_allocation.fee_frequency.$error">This information is required</span>
            </v-col>
            <v-col md="6">
              <v-menu
                  v-model="start_date_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="transport_allocation.start_date"
                      label="Start date" dense outlined
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="transport_allocation.start_date"
                    @input="start_date_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col md="6">
              <v-menu
                  v-model="end_date_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="transport_allocation.end_date"
                      label="End date" dense outlined
                      prepend-icon="" prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="transport_allocation.end_date"
                    @input="end_date_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" md="4">
              Status
              <v-switch
                  v-model="transport_allocation.is_active"
                  :label="transport_allocation.is_active?'Active':'Inactive'"
                  color="primary"

              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="btn btn-standard cancel-btn"
              depressed
              @click="cancelForm"
          >Cancel
          </v-btn>
          <v-btn
              class="text-white btn btn-primary"
              depressed
              v-if="checkIsAccessible('transport-allocation', 'create') || checkIsAccessible('transport-allocation', 'edit')"
              @click="createOrUpdate"
              :loading="loading"
          >
            {{ edit ? "Update" : "Save" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import TransportAllocationService from "@/core/services/Transport/transport_allocations/TransportAllocationService";
import userService from "@/core/services/user/UserService";
import StopsService from "@/core/services/Transport/StopsService";
import RoutesService from "@/core/services/Transport/Routes/RoutesService";
import {required, email, requiredIf} from "vuelidate/lib/validators";
const route=new RoutesService();
const stop=new StopsService();
const user=new userService();
const transportAllocation=new TransportAllocationService();
export default {
  validations: {
    transport_allocation:{
      user_id:{required},
      route_id:{required},
      stop_id:{required},
      fee:{required},
      fee_frequency:{required},
      discount:{
        required:requiredIf(function (nestedModel){
          return this.transport_allocation.discount_type;
        }),
      },
    }
  },
  data(){
    return{
      loading:false,
      userLoading:false,
      dialog:false,
      edit:false,
      transport_allocation:{
        user_id:null,
        route_id:null,
        stop_id:null,
        discount:null,
        discount_type:null,
        fee:null,
        start_date:null,
        end_date:null,
        fee_frequency:null,
        is_active:true,
      },
      userDetail:null,
      users:[],
      routes:[],
      stops:[],
      userSearch:null,
      start_date_menu:false,
      end_date_menu:false,
    }
  },
  methods:{
    filterUserData(item, queryText) {
      return (
          item.full_name.toLowerCase().includes(queryText.toLowerCase()) ||
          item.first_name.toLowerCase().includes(queryText.toLowerCase()) ||
          item.email.toLowerCase().includes(queryText.toLowerCase())

      );
    },
    changeUser(){
      if(this.userDetail){
        this.transport_allocation.user_id=this.userDetail.id;
      }
    },
    resetData(){
      this.$v.$reset();
      this.transport_allocation={
        user_id:null,
        route_id:null,
        stop_id:null,
        discount:null,
        discount_type:null,
        fee:null,
        start_date:null,
        end_date:null,
        fee_frequency:null,
        is_active:true,
      };
      this.users=[];
      this.routes=[];
      this.stops=[];
      this.userSearch=null;
      this.userDetail=null;
    },
    createTransportAllocation(){
      this.edit=false;
      this.resetData();
      this.openDialog();
      this.getAllActiveRoutes();
    },
    editTransportAllocation(item){
      this.edit=true;
      this.transport_allocation=item;

      this.openDialog();
      this.getAllActiveRoutes();
      this.changeStop();
      this.findUserDetail(item.user_id);
      this.changeUser();
    },
    findUserDetail(userId){
      user
          .show(userId)
          .then(response => {
            let userDetail=response.data.user;
            this.userDetail=userDetail;
            this.userDetail.display_text = `${userDetail.id_card_no ? userDetail.id_card_no + '-' : ''} ${userDetail.personal_id.toUpperCase()} - ${userDetail.full_name} - ${this.userDetail.email}`;

          })
          .catch(error => {

          })
          .finally(() => {
            this.loading = false;
          });

    },
    openDialog(){
      this.dialog=true;
    },
    closeDialog(){
      this.dialog=false;
    },
    createOrUpdate(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        if (!this.edit){
          this._create();
        }
        if (this.edit){
          this.update();
        }

      }
    },
    _create(){
     this.loading=true;
      transportAllocation
          .create(this.transport_allocation)
          .then(response => {
           this.$snotify.success("Information added");
            this.closeDialog();
            this.resetData();
            this.$emit('refresh');
          })
          .catch(error => {

          })
          .finally(() => {
            this.loading = false;
        });

    },
    update(){
      this.loading=true;
      transportAllocation
          .update(this.transport_allocation.id,this.transport_allocation)
          .then(response => {
            this.$snotify.success("Information updated");
            this.closeDialog();
            this.resetData();
            this.$emit('refresh');
          })
          .catch(error => {

          })
          .finally(() => {
            this.loading = false;
          });
    },
    getAllActiveRoutes(){
      route
          .activeAll()
          .then(response => {

           this.routes=response.data.routes;
          })
          .catch(error => {

          })
          .finally(() => {
            this.loading = false;
          });
    },

    changeStop(){

      this.getAllActiveStops();
    },
    getAllActiveStops(){
      stop
          .getByRoute(this.transport_allocation.route_id)
          .then(response => {
            this.stops=response.data.stops;
          })
          .catch(error => {

          })
          .finally(() => {
            this.loading = false;
          });
    },
    changeDiscountType(){
      this.transport_allocation.discount=null;
    },
    removeTransportAllocation(){
      this.userDetail=null;
      this.transport_allocation.user_id=null;
    },
    cancelForm(){
      this.resetData();
      this.closeDialog();
    }
  },
  watch: {
    userSearch(val) {
      this.userLoading=true;
      let data = {
        info:val,
      };
      user
          .search(data)
          .then((response) => {
            this.users = [];
            let users=[];

            users = response.data.users;
            if(users.length > 0 ){
                users.map(ele => {
                  ele.display_text = `${ele.id_card_no ? ele.id_card_no + '-' : ''} ${ele.personal_id.toUpperCase()} - ${ele.full_name} - ${ele.email}`;
                  this.users.push(ele)
                });
              }else{
                this.users=[];
              }

            })
            .catch(err => {

            })
            .finally(() => (
                this.userLoading = false
            ))


    },
  }
}
</script>