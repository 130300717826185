import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class TransportAllocationService{
    #api = null;
    constructor() {
        this.#api = `${API_URL}user/transport-allocation`;
    }
    paginate(data = {}, index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    create(data) {

        let url = `${this.#api}`;
        return apiService.post(url, data);

    }
    update(id, data) {
        let url = `${this.#api}/${id}`;
        return apiService.put(url, data)
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }
}