<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card ">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Transport allocation</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Transport allocation
                </div>
              </div>
              <div class="breadcrumb-right" v-if="checkIsAccessible('transport-allocation', 'create')">
                <div class="card-toolbar">
                  <v-btn @click="createNew()" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add new
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" md="3">
                  <v-text-field label="Name, phone, email, symbol no." v-model="search.info"
                    @input="search.info = $event !== null ? $event : ''" v-on:keyup.enter="getAllTransportAllocations"
                    clearable outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field label="Route" v-model="search.route_name"
                    @input="search.route_name = $event !== null ? $event : ''" v-on:keyup.enter="getAllTransportAllocations"
                    clearable outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select :items="is_current_status" clearable v-on:keyup.enter="getAllTransportAllocations"
                    v-model="search.is_current" label="Current status" item-text="text" item-value="value" outlined
                    dense></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" md="3">
                  <v-select :items="status" clearable v-on:keyup.enter="getAllTransportAllocations"
                    v-model="search.is_active" label="Status" item-text="text" item-value="value" outlined
                    dense></v-select>
                </v-col>

                <v-col cols="12" :md="!user ? 12 : 12" class="text-right">
                  <!-- <v-btn class="btn btn-primary btn-search w-35 float-right" :loading="loading">
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn> -->
                  <v-btn 
                                      :loading="loading"
                                      @click="getAllTransportAllocations()"
                                      class="btn btn-primary w-35"
                                      dark
                                    >
                                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                                    Search
                                    </v-btn>
                </v-col>
              </div>
            </div>
            <div class="table-responsive">
              <v-skeleton-loader type="table-thead" v-if="loading">
              </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                  <tr class="px-3">
                    <th class="wrap-column">Student</th>
                    <th class="wrap-column">Route</th>
                    <th class="wrap-column">Status</th>
                    <th class="wrap-column">Status</th>
                    <th class="wrap-column">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-show="transport_allocations.length > 0" v-for="(allocation, index) in transport_allocations"
                    :key="index">
                    <td class="wrap-column">
                      <span class="font-weight-medium">
                        {{ allocation.full_name }}
                      </span>
                      <div class="text-secondary subtitle-1">
                        <span class="font-weight-bold"> Batch:</span> {{ allocation.student_batch ?
                          allocation.student_batch : 'N/A' }}<br />
                      </div>
                      <span class="text-secondary subtitle-1">
                        <span class="font-weight-bold">Program :</span> {{ allocation.student_program }} <br />
                      </span>
                    </td>
                    
                    <td class="wrap-column">
                      <span class="font-weight-medium">
                        {{ allocation.route_name }}
                      </span>

                      <div class="mt-2">
                        <span class="font-weight-medium">
                          Stop: {{ allocation.stop_name }}
                        </span>
                      </div>

                      <div class="mt-2">
                        <span class="font-weight-medium">
                          Start date:  {{ allocation.start_date_format }}
                        </span>  
                      </div>

                      <div class="mt-2">
                        <span class="font-weight-medium">
                          End date: {{ allocation.end_date_format }}
                        </span>
                      </div>
                    </td>
                    

                    <td class="wrap-column pt-3">
                      <span class="badge  text-lg`"
                        v-bind:class="{ 'badge-primary': allocation.is_current, 'badge-warning': !allocation.is_current }">{{
                          allocation.is_current ? 'Current' : 'Not current' }}</span>
                    </td>

                    <td class="wrap-column pt-3">
                      <span class="badge text-lg`"
                        v-bind:class="{ 'badge-success': allocation.is_active, 'badge-danger': !allocation.is_active }">{{
                          allocation.is_active ? 'Active' : 'Inactive' }}</span>
                    </td>

                    <td class="pr-0 text-left">
                      <template>
                        <b-dropdown size="sm" variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret
                          right no-flip>
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('transport-allocation', 'edit')">
                              <a class="navi-link" @click="edit(allocation)">
                                <span class="navi-icon">
                                  <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('transport-allocation', 'delete')">
                              <a class="navi-link" @click.prevent="deleteAllocation(allocation.id)">
                                <span class="navi-icon">
                                  <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-show="transport_allocations.length == 0">
                    <td colspan="8" class="text-center">
                      <strong>Data not available</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
      <create-or-update ref="create-update" @refresh="getAllTransportAllocations"></create-or-update>
    </div>
  </v-app>
</template>
<script>
import TransportAllocationService from "@/core/services/Transport/transport_allocations/TransportAllocationService";
import CreateOrUpdate from "./CreateOrUpdate.vue";

const transportAllocation = new TransportAllocationService();
export default {
  components: {
    CreateOrUpdate,
  },

  data() {
    return {
      loading: false,
      transport_allocations: [],
      search: {
        info: null,
        is_current: 1,
        is_active: 1,
        route_name: ''
      },
      page: null,
      total: null,
      perPage: null,
      status: [
        { text: 'Active', value: 1 },
        { text: 'Inactive', value: 0 },
      ],
      is_current_status: [
        { text: 'Current', value: 1 },
        { text: 'Not current', value: 0 },
      ],
    }
  },
  methods: {
    createNew() {
      this.$refs['create-update'].createTransportAllocation();
    },
    edit(item) {
      this.$refs['create-update'].editTransportAllocation(item);
    },
    deleteAllocation(allocationId) {
      this.$confirm({
        message: `Are you sure you want to delete ? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            transportAllocation
              .delete(allocationId)
              .then((response) => {
                this.getAllTransportAllocations();
                this.$snotify.success("Transport allocation Information deleted");
              })
              .catch((err) => {
                this.$snotify.error("Oops something went wrong");
              });
          }
        },
      });
    },
    getAllTransportAllocations() {
      this.loading = true;
      transportAllocation
        .paginate(this.search, this.page)
        .then(response => {
          this.transport_allocations = response.data.data;
        })
        .catch((err) => {

        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getAllTransportAllocations();
  }
}
</script>